"use client";
import React from "react";

import Blur from "@/components/overlay/Blur";
import Color from "@/components/overlay/Color";
import Footer from "@/sections/Footer";
import Heroerror from "@/sections/Herorror";


export default function Page() {
    
/* 
    const [isMobile, setIsMobile] = useState(false);

    useEffectOnce(() => {
        window.scrollTo({
            top: 0,
            left: 0,
        });
        setIsMobile(window.innerWidth < 768);
    });

    useEventListener('resize', () => {
        setIsMobile(window.innerWidth < 768);
    }); */


    return (
        <>
            <Blur />
            <Color />
            {/*<ScrollerMotion // Weird, stuff happening with this
                disabled={isMobile}
                spring={{ mass: 1, stiffness:800, bounce: 300, damping: 100 }}
            >*/}
            <main
                className="flex flex-col items-center justify-center bg-black"
            >
                <Heroerror certificate={1} />
           
<Footer />
            </main>
            {/*</ScrollerMotion>*/}
        </>
    );
}
